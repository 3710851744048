import axios from 'axios';
const url = "https://zabbix.maida.health/api_jsonrpc.php"

const sortItems = (item1, item2) => {
    if (item1['hosts'] !== undefined && item2['hosts'] !== undefined) {
        const item1Name = item1['hosts'][0]['name']
        const item2Name = item2['hosts'][0]['name']
        if (item1Name.includes('-dev')) {
            if (!item2Name.includes('-dev')) return 1
        } else {
            if (item2Name.includes('-dev')) return -1
        }   
        if (item1Name.includes('-hmg')) {
            if (!item2Name.includes('-hmg')) return 1
        } else {
            if (item2Name.includes('-hmg')) return -1
        }        
        return item1Name.toString().localeCompare(item2Name.toString())
    } else {
        if (item1['name'] !== undefined && item2['name']) 
        return item1['name'].toString().localeCompare(item2['name'].toString())
    }
    return 0
}
const sortItemsByChecks = (item1, item2) => {
    if (item1.checks === undefined || item1.checks === []) {
        if (item2.checks === undefined || item1.checks === []) return 0;
        return 1; // b before a
    }
    if (item2.checks === undefined || item2.checks === []) {
        if (item1.checks === undefined || item1.checks === []) return 0;
        return -1; // a before b
    }
    return 0;
}

async function getAuth() {
    const payload = {
        jsonrpc: "2.0", 
        method: "user.login",
        params: { user: "heimdall", password: "Vv92lVygSXc9LmlE9hQDyY7K3m" },
        id: 1, auth: null 
    }
    const ret = await axios.post( url, payload )
    if (ret === undefined || ret === null) return null
    return ret.data.result
}

async function getGatewayItems(auth) {
    const response = await axios.post(
        url,
        {
            jsonrpc: "2.0",
            method: "item.get",
            params: {
                expandName : true,
                sortfield: "name",
                groupids: [ 30 ],
                selectHosts: [ "name" ],
                search: { name: "ICMP ping", groupip: 30 }
            },
            id: 2,
            auth: auth
        }
    );
    if (response.data.result === undefined) 
        return [];
    let maybeItems = response.data.result.map(
        function (item) {
            //console.log(item.hosts[0].name)
            return {
                kind: "Ping",
                httptestid: item.hostid,
                applicationid: item.applicationid,
                hostid: item.hostid,
                hosts: item.hosts,
                name: item.hosts[0].name,
                nextcheck: null,
                status: item.status,
                itemid: item.itemid,
                detail: false,
                url: null,
                checks: []
            }
        }
    )
    //#maybeItems = maybeItems.sort( sortItems )
    return getGatewayHistory(maybeItems, auth, 2)
}

async function getGatewayHistory(items, auth, hoursToGet) {
    if (auth === null) return [];
    if (items === undefined || items.length === 0 ) return [];
    let itemids = items.filter(el => el.itemid !== undefined).map(el => el.itemid);
    //console.log(itemids)
    if (itemids === undefined || itemids.length === 0) return [];
    const dt = new Date();
    //console.log(dt.getTime())
    dt.setTime(dt.getTime() - (hoursToGet*60*60*1000)); // remove hoursToGet hours
    const newItems = [...items];
    const time_from = (Math.round(dt.getTime()/1000))
    //console.log(time_from)
    const response = await axios.post(
        url,
        {
            jsonrpc: "2.0",
            method: "history.get",
            timeout: 3000,
            params: {
                output : "extend",
                sortfield: ["itemid"],
                sortorder: "DESC",
                time_from: time_from,
                itemids: itemids
            },
            id: 5,
            auth: auth
        }
    ).catch(error => {
        //console.log(error);
    });
    //console.log("got.")
    //console.log(response.data)
    newItems.forEach( item => { item.checks = [] });
    let defaultItem = undefined;
    if (response.data.result === undefined) 
        return [];
    response.data.result.reverse().forEach( history => {
        if (defaultItem === undefined || history.itemid !== defaultItem.itemid ) {
            defaultItem = newItems.find( el => el.itemid !== undefined && el.itemid.toString() === history.itemid.toString());
            defaultItem.checks = []
        }
        if (defaultItem !== undefined) {
            // inverte valores pois ping e webchecks contam inversamente    
            if (defaultItem['kind'] === "Ping") history["value"] = history["value"] === "1" ? "0":"1"
            defaultItem.checks.push(history);
        }
    });
    return newItems.filter(item => item.checks.length>0).sort( sortItemsByChecks )
}

async function getItems(auth) {
    const response = await axios.post(
        url,
        {
            jsonrpc: "2.0",
            method: "httptest.get",
            params: {
                expandName : true,
                sortfield: "name",
                selectHosts: [ "name" ]
            },
            id: 2,
            auth: auth
        }
    );
    if (response.data.result === undefined) 
        return [];
    let maybeItems = response.data.result.map(
        function (item) {
            return {
                kind: "Webcheck",
                httptestid: item.httptestid,
                applicationid: item.applicationid,
                hostid: item.hostid,
                hosts: item.hosts,
                name: item.name,
                nextcheck: item.nextcheck,
                status: item.status,
                itemid: null,
                detail: false,
                url: null,
                checks: []
            }
        }
    )
    maybeItems = maybeItems.sort( sortItems )
    return getWebChecks(maybeItems, auth)
}

async function getWebChecks(items, auth) {
    const response = await axios.post(
        url,
        {
            jsonrpc: "2.0",
            method: "item.get",
            params: {
                output : ["itemid", "hostid", "name", "key", "url"],
                webitems: true,
                filter: { key_: "web.test.fail[url]" }
            },
            id: 3,
            auth: auth
        }
        )
    if (response.data.result === undefined) return [];
    response.data.result.forEach(webitem => {
        const found = items.find(el => el.hostid === webitem.hostid)
        if (found !== undefined) {
            found.itemid = webitem.itemid;
        }
    });
    //if (changed) { setItems(newItems) }
    return getURLs( items.filter(item => item.itemid !== null),  auth)
}

async function getURLs(items, auth) {
    const response = await axios.post(
        url,
        {
            jsonrpc: "2.0",
            method: "usermacro.get",
            params: {
                output : ["hostid", "value"],
                webitems: true,
                filter: { macro: "{$URL}" }
            },
            id: 4,
            auth: auth
        }
    )
    if (response.data.result === undefined)
        return getHistory( items,  auth , 2);
    response.data.result.forEach(urlinfo => {
        const found = items.find(el => el.hostid === urlinfo.hostid)
        if (found !== undefined) { found.url = urlinfo.value; }
    });
    //if (changed) { setItems(newItems) }
    return getHistory( items,  auth , 2)
}
    
async function getHistory(items, auth, hoursToGet) {
    //console.log(items.length)
    //console.log("starting history")
    if (auth === null) return [];
    if (items === undefined || items.length === 0 ) return [];
    let itemids = items.filter(el => el.itemid !== undefined).map(el => el.itemid);
    if (itemids === undefined || itemids.length === 0) return [];
    const dt = new Date();
    dt.setTime(dt.getTime() - (hoursToGet*60*60*1000)); // remove 1 hour
    const newItems = [...items];
    const response = await axios.post(
        url,
        {
            jsonrpc: "2.0",
            method: "history.get",
            params: {
                output : "extend",
                sortfield: ["itemid","clock"],
                sortorder: "DESC",
                time_from: (Math.round(dt.getTime()/1000)).toString(),
                itemids: itemids
            },
            id: 5,
            auth: auth
        }
    );
    newItems.forEach( item => { item.checks = [] });
    let defaultItem = undefined;
    if (response.data.result === undefined) 
        return [];
    response.data.result.reverse().forEach( history => {
        if (defaultItem === undefined || history.itemid !== defaultItem.itemid ) {
            defaultItem = newItems.find( el => el.itemid !== undefined && el.itemid.toString() === history.itemid.toString());
            defaultItem.checks = []
        }
        if (defaultItem !== undefined) {
            // inverte valores pois ping e webchecks contam inversamente    
            if (defaultItem['kind'] === "Ping") history["value"] = history["value"] === "1" ? "0":"1"
            defaultItem.checks.push(history);
        }
    });
    return newItems.filter(item => item.checks.length>0).sort( sortItemsByChecks )
}

async function getExtendedHistory(item, auth, hoursToGet) {
    const dt = new Date();
    dt.setTime(dt.getTime() - (hoursToGet*60*60*1000)); // last 24 hours
    const response = await axios.post(
        url,
        {
          jsonrpc: "2.0",
          method: "history.get",
          params: {
            output : "extend",
            sortfield: ["itemid","clock"],
            sortorder: "DESC",
            time_from: (Math.round(dt.getTime()/1000)).toString(),
            itemids: [item.itemid.toString()],
          },
          id: 4,
          auth: auth
        }
    );
    if (response.data.result === undefined) return [];
    return response.data.result;
}

async function getAllItems(auth) {
    const webcheckItems = await getItems(auth)
    const gatewayItems = await getGatewayItems(auth)
    return [...webcheckItems, ...gatewayItems]
}
    
export {getAuth, getAllItems, getGatewayItems, getItems, getHistory, getExtendedHistory, getWebChecks};