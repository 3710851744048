import { useState, useEffect } from 'react';
import { getAuth, getItems, getGatewayItems, getHistory } from '../api/Api'
import Item from './Item';
import ItemDetail from './ItemDetail';
import Loading from '../layout/Loading';
import './Items.css';

const Items = () => {

  const maxCountdownValue = 60
  const [seconds, setSeconds] = useState(maxCountdownValue)
  const [auth,      setAuth     ] = useState(null) 
  const [items,     setItems    ] = useState([])
  const [selected,  setSelected ] = useState(null)
  const [tab,       setTab        ] = useState(null) 
  const [gatewayItems, setGatewayItems] = useState([])
  
  function handleItemSelect(item) { setSelected(item) }

  function handleTabSelectNull() {
    setTab(null)
  }  
  function handleTabSelectPlanserv() {
    setTab('eco')
  }
  function handleTabSelectEco() {
    setTab('eco')
  }
  function handleTabSelectGdf() {
    setTab('gdf')
  }
  function handleTabSelectNet() {
    setTab('net')
  }
  const filterInEco = (item) => item['hosts'][0]['name'].startsWith("pv-") || item['hosts'][0]['name'].startsWith("pt-") || item['hosts'][0]['name'].includes('planserv');
  const filterOutEco = (item) => !filterInEco(item)
  const filterInPlanserv = (item) => item['hosts'][0]['name'].startsWith("pv-") || item['hosts'][0]['name'].startsWith("pt-") || item['hosts'][0]['name'].includes('planserv');
  const filterOutPlanserv = (item) => !filterInPlanserv(item);
  const filterInGdf = (item) => item['hosts'][0]['name'].startsWith("df-");
  const filterOutGdf  = (item) => !filterInGdf(item);
  const notHiddenItem = (item) => !item['name'].includes('hidden')

  const itemsView = (items, tab) => {
    if (items === undefined || items.length === 0) return (<Loading />)
    var itemsToShow = [];
    switch (tab) {
      case 'eco': itemsToShow = items.filter(notHiddenItem).filter(filterInPlanserv)
        break;
      case 'gdf': itemsToShow = items.filter(notHiddenItem).filter(filterInGdf)
        break;
      case 'net': itemsToShow = gatewayItems
        break;
      default: itemsToShow = items.filter(notHiddenItem).filter(filterOutPlanserv).filter(filterOutGdf)
    }

    //console.log(itemsToShow[0])
    if (itemsToShow.length === 0)
        return (
          <div className="App-items">
            <center>Nenhum Webcheck cadastrado neste ambiente.</center>
          </div>
        )  
    return (
      <div className="App-items">
        {itemsToShow.map((item) => <Item key={item.httptestid} element={item} onSelect={handleItemSelect} /> )}
      </div>
    )
  }

  useEffect(()=>{
    let myInterval = setInterval(() => {
      if (seconds > 0) { setSeconds(seconds - 1); }
      if (seconds === 0) { 
        clearInterval(myInterval) 
        getHistory(items, auth, 2).then( response => { 
          setItems(response) 
          setSeconds(maxCountdownValue)
        })
        getGatewayItems(auth).then( response => setGatewayItems(response) )
      } 
    }, 1000)
    return () => { clearInterval(myInterval); };
  });
  
  useEffect( () => { 
    getAuth().then( ret => {if (ret !== null) setAuth(ret); }) 
  }, [] )
  useEffect( () => { 
    if (auth !== undefined) {
      getItems(auth).then( response => setItems(response) )
      getGatewayItems(auth).then( response => setGatewayItems(response) )
    }
  }, [auth] )

  if (items === undefined || items.length === 0) return ( <Loading absolute={true} /> )
  if (selected !== null) return (
    <div className="App-content">
      <h2 className="head">
        <a href="#top" className="click" onClick={() => handleItemSelect(null)}>🏠</a>
        <span className="text-muted"> | </span>
        <span>Detalhamento de Aplicação</span> 
        <small className="text-muted"> (últimas vinte e quatro horas)</small>
      </h2>
      <ItemDetail key={selected.httptestid} item={selected} onSelect={() => handleItemSelect(null)} auth={auth} />
    </div>
  )  
  return (
    <div className="App-content">
      <h2 className="head">
        <span>{tab==="net"?"Status da Internet Maida":"Status das Aplicações"}</span>
        <small className="pull-right text-muted">
          { seconds === 0 ? <Loading absolute={true} /> : `atualização em ${seconds}...` }
        </small>
      </h2>
      <h2 id="tabs" class={tab}>
        <a href="#maida" class="click null" onClick={handleTabSelectNull}>Maida</a>
        <a href="#eco" class="click eco" onClick={handleTabSelectPlanserv}>Eco - Planserv</a>
        <a href="#gdf" class="click gdf" onClick={handleTabSelectGdf}>Eco - GDF</a>
        <a href="#net" class="click net" onClick={handleTabSelectNet}>Internet</a>
      </h2>
      { 
        (items === undefined || items.length === 0) ? 
        <Loading /> : itemsView(items,tab)
      }
    </div>
  );
}

export default Items;
