import { useState } from 'react';
import { getExtendedHistory } from '../api/Api';
import Status from '../components/Status';
import Loading from '../layout/Loading';
import './Item.css';

/*Item: status, nextcheck, hosts, name */

const ItemDetail = ({item, auth, onSelect}) => {

  //const [purechecks, setPureChecks] = useState(null)
  const [checks, setChecks] = useState(null)

  function getChecks(auth) {
    getExtendedHistory(item, auth, 24).then( response => {
      if (response === undefined) return;
      //setPureChecks(response)
      let responseChecks = {};
      for (const check of response) {
        const dateTime = new Date(check.clock*1000)
        const key = dateTime.getMonth().toString() + "-" + dateTime.getDate().toString() + "-" + dateTime.getHours().toString()
        if (!(key in responseChecks) || responseChecks[key] === undefined) { responseChecks[key] = []; }
        responseChecks[key] = [check, ...responseChecks[key]]
      }
      setChecks(responseChecks)
    });
  }

  if (checks === null) getChecks(auth)

  const formatDateTime = (timestamp) => {
    const time = new Date((timestamp*1000))
    const hour = time.getHours();
    const minute = time.getMinutes();
    return (hour<10?"0":"")+hour+":"+(minute<10?"0":"")+minute
  }

  const printChecks = (checks) => {
    if (checks === undefined || checks === null) return <Loading />
    return Object.entries(checks).map( ([key, checkGroup]) => (
      <div key={key} id={key} className="info-check-graph flex flex-space-between">
        <small className={"color_"+checkGroup[0].value.toString()}>{formatDateTime(checkGroup[0].clock)}</small>
        <div className="check-graph">
          {checkGroup.map( (check) => (
            <div key={check.clock} data-title={formatDateTime(check.clock)} title={formatDateTime(check.clock)} className={"color_"+check.value}>&nbsp;</div>
          ) )}
        </div>
        <small className={"color_"+checkGroup[checkGroup.length-1].value.toString()}>{formatDateTime(checkGroup[checkGroup.length-1].clock)}</small>
      </div>
    ) )
  }

  
  if (item === undefined || item["nextcheck"] === "0" || item["status"] === "1") return <Loading />
  return (
    <>
      <div className="item card detail">
        <div className="pull-right"><Status status={item['status']} checks={item['checks']} /></div>
        <h2 className="card-title name">
            {item['hosts'][0]['name']}
        </h2>
        {item.url !== null 
          ? (<a href={item.url} title={item.url} target="_blank" rel="noreferrer">{item.url}</a>) 
          : ""
        }
        <p>Webcheck <em>{item['name']}</em></p>
        <h4><center>Informações das Últimas 24h</center></h4>
        <div className="itemdetail-content">{printChecks(checks)}</div>
      </div>
    </>
  );
}

export default ItemDetail;
