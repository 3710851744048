import { useState } from 'react';
import Status from '../components/Status';
import './Item.css';

/*Item: status, nextcheck, hosts, name */

const Item = ({element, onSelect}) => {

  const [item] = useState(element);
  
  const handleItemClick = (item) => {
    onSelect(item)
  }

  const formatDateTime = (timestamp, withSeconds = false) => {
    const time = new Date((timestamp*1000))
    const hour = time.getHours();
    const minute = time.getMinutes();
    const second = time.getSeconds();
    if (withSeconds)
      return (hour<10?"0":"")+hour+":"+(minute<10?"0":"")+minute+":"+(second<10?"0":"")+second
      return (hour<10?"0":"")+hour+":"+(minute<10?"0":"")+minute
  }

  const showInfoData = (checks) => {
    if (checks === undefined || checks.length === 0) return <></>
    return (
      <div className="info-check-graph flex flex-space-between">
        <small>{formatDateTime(checks[0].clock)}</small>
        <small title={formatDateTime(checks[checks.length-1].clock)}>Agora</small>
      </div>
    )
  }

  const showInfoOnlineOffline = (value) => ( value === "0" ? "Online" : "Offline")
  
  if (item === undefined || item["nextcheck"] === "0" || item["status"] === "1") return <></>
  return (
    <div className={"item card " + item['kind']}>
      <div className="pull-right"><Status kind={item['kind']} status={item['status']} checks={item['checks']} /></div>
      <h3 className="card-title">
        <a href={"#" + item.itemid} title={ "Ver Detalhes de " + item['hosts'][0]['name']} onClick={() => handleItemClick(item)} className="name" rel="noreferrer">
          {item['hosts'][0]['name'].replace(/(-app)?-pro?d$/,'').replace(/Producc?ao/,'').replace('.infoway.cloud','').replace(/-live$/,'')}
        </a>
      </h3>
      {item.url !== null 
        ? (<a className="card-link text-muted" href={item.url} title={item.url} target="_blank" rel="noreferrer">{item.url}</a>) 
        : ""
      }
      {showInfoData(item.checks)}
      <div className="check-graph">
        {item.checks.map( (check) => (
          <div key={check.clock} data-title={formatDateTime(check.clock, true)} 
            title={formatDateTime(check.clock, true) + ": " + showInfoOnlineOffline(check.value)} 
            className={"color_"+check.value}>&nbsp;</div>
        ) )}
      </div>
      <p className="card-footer">{item['kind']} <em>{item['name']}</em></p>
    </div>
  );
}

export default Item;
