import maida from '../img/maida.svg'
import heimdall from '../img/heimdall.svg'
import './Head.css';

function Head() {
  return (
    <header className="App-header">
      <a href="https://maida.health" rel="noreferrer" target="_blank"><img src={maida} className="maida-logo" alt="Maida" /></a>
      <div className="divider"></div>
      <h1 className="App-title"><a href="./"><img src={heimdall} alt="Logo" /> heimdall</a></h1>
    </header>
  );
}

export default Head;
