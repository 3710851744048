import './Loading.css';
import oval from '../img/oval.svg'

function Loading({absolute}) {
    return (
      <div className={(absolute === true ? "absolute": "") + " loader"}>
          <img src={oval} alt="Carregando..." />
      </div>
    );
  }
  
  export default Loading