import './Status.css';

function emoji(checks, status) {
  if (checks === undefined || checks.length === 0) {
    return <>⏳</>
  } else {
    const last = checks.length > 0 ? checks[checks.length-1] : undefined
    const greens = checks.reduce( (prevValue, item) => prevValue + (item["value"].toString() === "0"), 0 )
    let uptimeInfo = (greens / checks.length * 100).toString()
    uptimeInfo = (last.value.toString() === "1") ? "Aplicação Offline momentaneamente. Já estamos corrigindo!" :
      (greens / checks.length < 0.9) ? "Aplicação offline em mais de 10% do período escolhido ("+uptimeInfo+"%). Nossos experts já estão verificando uma correção" :
        "Aplicação Online";
    
    if (last.value.toString() === "1" ) return <span className="red" title={uptimeInfo}>❌</span>
    if (greens / checks.length < 0.9) return <span className="yellow" title={uptimeInfo}>⚠️</span>
    return <span className="green" title={uptimeInfo}>✔️</span>
  }
}

const Status = ({status, checks}) => {
  return (
    <span className="status">{emoji(checks, status)}</span>
  );
}

export default Status;
